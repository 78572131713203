import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Shuffle, Download, Dices } from 'lucide-react';
import { pwaInstallHandler } from 'pwa-install-handler';

// Define the Card type
type Card = {
  suit: 'hearts' | 'diamonds' | 'clubs' | 'spades';
  value: string;
};

// Create a deck of cards
const createDeck = (): Card[] => {
  const suits: Card['suit'][] = ['hearts', 'diamonds', 'clubs', 'spades'];
  const values = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];
  return suits.flatMap(suit => values.map(value => ({ suit, value })));
};

const CardDeckViewer: React.FC = () => {
  const [deck, setDeck] = useState<Card[]>(createDeck());
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [installPrompt] = useState<(() => void) | null>(null);

  pwaInstallHandler.addListener((canInstall) => {
    //console.log('Can install? ' + canInstall);
  })

  const shuffleDeck = () => {
    setDeck([...deck].sort(() => Math.random() - 0.5));
    setCurrentCardIndex(0);
  };

  const jumpToRandomCard = () => {
    setCurrentCardIndex(Math.floor(Math.random() * deck.length));
  };

  const nextCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % deck.length);
  };

  const prevCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex - 1 + deck.length) % deck.length);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 150) {
      nextCard();
    }

    if (touchStart - touchEnd < -150) {
      prevCard();
    }
  };

  const handleInstall = () => {
    if (installPrompt) {
      pwaInstallHandler.install();
    }
  };

  const currentCard = deck[currentCardIndex];

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center bg-gray-100">
      <div
        className="w-full h-full flex items-center justify-center"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <button onClick={prevCard} className="absolute left-4">
          <ChevronLeft size={48} />
        </button>
        <svg width="607" height="807" viewBox="68.547241 122.68109 607 807">
          <rect width="600" height="800" x="72.047" y="126.18" fill="#fff" stroke="#000" strokeWidth="5" color="#000" display="block" rx="17.143" ry="17.143" />
          <text x="200" y="700" fontSize="500" fill={currentCard.suit === 'hearts' || currentCard.suit === 'diamonds' ? 'red' : 'black'}>
            {currentCard.suit === 'hearts' ? '♥' : currentCard.suit === 'diamonds' ? '♦' : currentCard.suit === 'clubs' ? '♣' : '♠'}
          </text>
          <text x="80" y="200" strokeWidth="1pt" fontFamily="Bitstream Vera Serif" fontSize="90" fill={currentCard.suit === 'hearts' || currentCard.suit === 'diamonds' ? 'red' : 'black'}>{currentCard.value}</text>          
          <text x="80" y="275" fontSize="90" fill={currentCard.suit === 'hearts' || currentCard.suit === 'diamonds' ? 'red' : 'black'}>
            {currentCard.suit === 'hearts' ? '♥' : currentCard.suit === 'diamonds' ? '♦' : currentCard.suit === 'clubs' ? '♣' : '♠'}
          </text>
          <text x="-660" y="-855" strokeWidth="1pt" fontFamily="Bitstream Vera Serif" fontSize="90" transform="scale(-1)" fill={currentCard.suit === 'hearts' || currentCard.suit === 'diamonds' ? 'red' : 'black'}>{currentCard.value}</text>
          <text x="-660" y="-780" fontSize="90" transform="scale(-1)" fill={currentCard.suit === 'hearts' || currentCard.suit === 'diamonds' ? 'red' : 'black'}>
            {currentCard.suit === 'hearts' ? '♥' : currentCard.suit === 'diamonds' ? '♦' : currentCard.suit === 'clubs' ? '♣' : '♠'}
          </text>
        </svg>
        <button onClick={nextCard} className="absolute right-4">
          <ChevronRight size={48} />
        </button>
      </div>
      <div className="flex space-x-4 my-4">
        <button onClick={shuffleDeck} className="bg-blue-500 text-white p-2 rounded">
          <Shuffle size={24} />
        </button>
        <button onClick={jumpToRandomCard} className="bg-green-500 text-white p-2 rounded">
          <Dices size={24} />
        </button>
        <button onClick={handleInstall} className="bg-purple-500 text-white p-2 rounded">
          <Download size={24} />
        </button>
      </div>
    </div>
  );
};

export default CardDeckViewer;