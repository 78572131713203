import React from 'react';
import CardDeckViewer from './components/CardDeckViewer';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="bg-blue-500 text-white p-4 text-center">
        <h1 className="text-2xl font-bold">Card Deck Viewer</h1>
      </header>
      <main>
        <CardDeckViewer />
      </main>
      <footer className="bg-gray-200 p-4 text-center">
        <p>&copy; 2024 Card Deck Viewer PWA</p>
      </footer>
    </div>
  );
};

export default App;
